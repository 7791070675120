var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"questionList"}},[_vm._l((_vm.listData),function(question,index){return _c('div',{key:index,staticClass:"questions"},[_c('div',{staticClass:"descriptions"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(question.question))]),_c('div',{staticClass:"acceptButton"},[_c('el-button',{staticClass:"acceptBtn",attrs:{"round":""},on:{"click":function($event){return _vm.acceptQuestion(question)}}},[_vm._v("接受题目")])],1)]),_c('div',{staticClass:"pictures",staticStyle:{"margin-top":"12px","width":"90%"}},_vm._l((question.pictures),function(image,index){return _c('el-image',{key:index,staticStyle:{"width":"100px","height":"100px","margin-right":"12px"},attrs:{"src":image},on:{"click":function($event){_vm.showIndex = index;
          _vm.showViewer = true;
          _vm.currentPictures = question.pictures;}}})}),1)])}),(_vm.showViewer)?_c('el-image-viewer',{attrs:{"initial-index":_vm.showIndex,"url-list":_vm.currentPictures,"on-close":() => {
        _vm.showViewer = false;
      }}}):_vm._e(),_c('el-dialog',{attrs:{"title":"题目详情","visible":_vm.detailVisible,"close-on-click-modal":false},on:{"update:visible":function($event){_vm.detailVisible=$event}}},[_c('div',[_c('div',{staticClass:"questionDetail"},[_vm._v(" "+_vm._s(_vm.currentQuestion.question)+" ")]),_c('div',{staticClass:"pictures",staticStyle:{"margin-top":"12px","width":"90%"}},_vm._l((_vm.currentQuestion.pictures),function(image,index){return _c('el-image',{key:index,staticStyle:{"width":"100px","height":"100px","margin-right":"12px"},attrs:{"src":image},on:{"click":function($event){_vm.showIndex = index;
            _vm.showViewer = true;
            _vm.currentPictures = _vm.currentQuestion.pictures;}}})}),1)]),_c('div',{staticStyle:{"text-align":"center"},attrs:{"slot":"footer"},slot:"footer"},[_c('div',{staticStyle:{"color":"#e02020","text-align":"center","margin-bottom":"20px"}},[_vm._v(" 接受题目后，请在24小时内提交答案 ")]),_c('el-button',{attrs:{"round":"","type":"primary"},on:{"click":_vm.accept}},[_vm._v("接受题目")])],1)]),(_vm.total > 10)?_c('el-pagination',{staticStyle:{"padding":"20px","text-align":"center"},attrs:{"current-page":_vm.currentPage,"total":_vm.total,"layout":"prev, pager, next"},on:{"update:currentPage":function($event){_vm.currentPage=$event},"update:current-page":function($event){_vm.currentPage=$event},"current-change":_vm.getQuestionList}}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }